:root {
    font-size: 60%;
    --color-background: #fff;
    --unnamed-color-4a3f32: #4A3F32;
    --unnamed-color-d5b68f: #bb2025;
    --unnamed-color-827566: #827566;
    /* Font/text values */
    --unnamed-font-family-raleway: 'Raleway', sans-serif;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-300: 300;
    --unnamed-font-weight-600: 600;
    --unnamed-font-size-14: 14px;
    --unnamed-font-size-18: 18px;
    --unnamed-font-size-20: 20px;
    --unnamed-font-size-24: 24px;
    --unnamed-line-spacing-14: 14px;
    --unnamed-line-spacing-18: 18px;
    --unnamed-line-spacing-20: 20px;
    --unnamed-line-spacing-24: 24px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-character-spacing-1: 1px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, strong, span, a, li {
    font-family: var(--unnamed-font-family-raleway);
}

html, body, #root {
    height: 100vh;
}

body {
    background: var(--color-background);
}

#root {
    display: flex;
    flex-direction: column;
}